<template>
  <div>
    <BaseForm :title="title" :mod="mod" :flat="flat" :divider="divider" :dialog="dialog" :loading="loading"
      @save="save()">
      <template #content>
        <v-row>
          <v-col>
            <v-text-field hide-details label="Nome" v-model="form.name" class="required"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="!form.allCategories">
          <v-col>
            <v-autocomplete class="required" :loading="loadingAdCategories" :disabled="loadingAdCategories"
              :items="adCategories" v-model="form.AdCategories"
              label="Associar com as seguintes categorias de anuncios:" item-text="text" item-value="value" multiple
              clearable flat hide-no-data hide-details></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="form.allCategories" label="Associar todas as categorias de anuncios"></v-checkbox>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingAdCategories: false,
    form: {
      AdCategories: [],
      allCategories: false
    },
    adCategories: [],
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.name) errors.push("O campo NOME é obrigatório");

      if (!this.form.AdCategories.length && this.form.allCategories === false)
        errors.push("Associe a pelo menos uma categoria de anúncio");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/news-categories`, this.form);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/news-categories/${this.$route.params.id}`,
          this.form
        );

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadCategoryData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/news-categories/${this.$route.params.id}`
        );

        this.setCategoryData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setCategoryData(data) {
      const cpData = { ...data };

      const { AdCategories, ...rest } = cpData;

      this.form = rest;

      if (AdCategories.length === this.adCategories.length) {
        this.form.allCategories = true;
        this.form.AdCategories = [];
      } else {
        this.form.allCategories = false;
        this.form.AdCategories = AdCategories.map((item) => item.id);
      }


    },
    async loadAdCategories() {
      try {
        this.loadingAdCategories = true;

        let url = `posts/categories`;

        const response = await this.$axios.get(url);

        this.setAdCategories(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingAdCategories = false;
      }
    },
    setAdCategories(data) {
      const parsedData = [...data];

      this.adCategories = parsedData.map((c) => ({
        text: c.name,
        value: c.id,
      }));
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = {};
    },
  },
  created() {
    this.loadAdCategories();

    if (this.mod === "update") this.loadCategoryData();
  },
};
</script>

<style></style>
